// src/components/DynamicForm.js

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUpload, FaTimesCircle, FaChevronDown } from 'react-icons/fa';
import axios from 'axios';

const questions = [
  {
    question: "Where are you located? Knowing your location helps us suggest styles that fit your vibe and climate",
    type: "dropdown",
    // options: ["United States", "Canada", "United Kingdom", "Europe", "Asia", "Australia", "Middle East", "South America", "Africa", "South America"],
    options: [
      { label: "United States", icon: "🇺🇸", value: "United States" },
      { label: "Canada", icon: "🇨🇦", value: "Canada" },
      { label: "United Kingdom", icon: "🇬🇧", value: "United Kingdom" },
      { label: "Germany", icon: "🇩🇪", value: "Germany" },
      { label: "France", icon: "🇫🇷", value: "France" },
      { label: "Italy", icon: "🇮🇹", value: "Italy" },
      { label: "Spain", icon: "🇪🇸", value: "Spain" },
      { label: "India", icon: "🇮🇳", value: "India" },
      { label: "China", icon: "🇨🇳", value: "China" },
      { label: "Japan", icon: "🇯🇵", value: "Japan" },
      { label: "South Korea", icon: "🇰🇷", value: "South Korea" },
      { label: "Singapore", icon: "🇸🇬", value: "Singapore" },
      { label: "Australia", icon: "🇦🇺", value: "Australia" },
      { label: "New Zealand", icon: "🇳🇿", value: "New Zealand" },
      { label: "Brazil", icon: "🇧🇷", value: "Brazil" },
      { label: "Argentina", icon: "🇦🇷", value: "Argentina" },
      { label: "South Africa", icon: "🇿🇦", value: "South Africa" },
      { label: "Nigeria", icon: "🇳🇬", value: "Nigeria" },
      { label: "Egypt", icon: "🇪🇬", value: "Egypt" },
      { label: "Saudi Arabia", icon: "🇸🇦", value: "Saudi Arabia" },
      { label: "United Arab Emirates", icon: "🇦🇪", value: "United Arab Emirates" },
      { label: "Mexico", icon: "🇲🇽", value: "Mexico" },
      { label: "Russia", icon: "🇷🇺", value: "Russia" },
      { label: "Turkey", icon: "🇹🇷", value: "Turkey" },
      { label: "Indonesia", icon: "🇮🇩", value: "Indonesia" },
      { label: "Thailand", icon: "🇹🇭", value: "Thailand" },
      { label: "Vietnam", icon: "🇻🇳", value: "Vietnam" },
      { label: "Malaysia", icon: "🇲🇾", value: "Malaysia" },
      { label: "Philippines", icon: "🇵🇭", value: "Philippines" },
      { label: "Kenya", icon: "🇰🇪", value: "Kenya" },
      { label: "Ghana", icon: "🇬🇭", value: "Ghana" },
    ],    
  },
  {
    question: "How would you describe your body shape? This helps us recommend fits that look amazing on you!",
    type: "dropdown",
    // options: ["Slim & Straight", "Athletic", "Curvy", "Hourglass", "Broad Shoulders", "Plus Size XXL"],
    options: [
      { label: "Slim & Straight - Lean and elongated frame.", icon: "🏃‍♀️", value: "Slim & Straight" },
      { label: "Athletic - Toned and defined with a muscular build", icon: "💪", value: "Athletic" },
      { label: "Curvy - Defined waist with balanced upper and lower body curves", icon: "🔵", value: "Curvy" },
      { label: "Hourglass - Curvy with a balanced upper and lower body", icon: "⌛", value: "Hourglass" },
      { label: "Broad Shoulders - Broader upper body with a tapered waist.", icon: "🏋️‍♂️", value: "Broad Shoulders" },
      { label: "Plus Size XXL", icon: "🛍️", value: "Plus Size XXL" },
    ],    
  },
  {
    question: "What season are you dressing for? Each one has its own charm, and we want your style to match",
    type: "dropdown",
    // options: ["Spring", "Summer", "Autumn/Fall", "Winter"],
    options: [
      { label: "Spring", icon: "🌸", value: "Spring" },
      { label: "Summer", icon: "☀️", value: "Summer" },
      { label: "Autumn/Fall", icon: "🍂", value: "Autumn/Fall" },
      { label: "Winter", icon: "❄️", value: "Winter" },
    ],    
    // options: ["Spring", "Summer", "Autumn/Fall", "Winter", "Diwali (Festive)"],
  },
  {
    question: "What's the occasion? We want your outfit to fit the moment perfectly",
    type: "dropdown",
    options: [
      { label: "Casual Day Out", icon: "🛍️", value: "Casual Day Out" },
      { label: "Work/Professional", icon: "💼", value: "Work/Professional" },
      { label: "Evening/Party", icon: "🎉", value: "Evening/Party" },
      { label: "Formal Event", icon: "🤵", value: "Formal Event" },
      { label: "Active/Sports", icon: "🏃", value: "Active/Sports" },
      { label: "Vacation", icon: "✈️", value: "Vacation" },
    ],    
    // options: ["Casual Day Out", "Work/Professional", "Evening/Party", "Formal Event", "Active/Sports", "Vacation"],
    // options: ["Casual Day Out", "Work/Professional", "Evening/Party", "Formal Event", "Active/Sports", "Vacation", "Diwali Celebration"],
  },
  {
    question: "How would you define your style vibe?",
    type: "dropdown",
    options: [
      { label: "Classic & Timeless", icon: "🕰️", value: "Classic & Timeless" },
      { label: "Trendy & Bold", icon: "⚡", value: "Trendy & Bold" },
      { label: "Casual & Laid-back", icon: "👕", value: "Casual & Laid-back" },
      { label: "Minimalist & Clean", icon: "⬜", value: "Minimalist & Clean" },
      { label: "Bohemian", icon: "🪶", value: "Bohemian" },
      { label: "Chic", icon: "👠", value: "Chic" },
      { label: "Edgy & Modern", icon: "🖤", value: "Edgy & Modern" },
      { label: "Streetwear", icon: "🧢", value: "Streetwear" },
      { label: "Sporty", icon: "🏀", value: "Sporty" },
    ],
    // options: ["Classic & Timeless", "Trendy & Bold", "Casual & Laid-back", "Minimalist & Clean", "Bohemian", "Chic", "Edgy & Modern", "Streetwear", "Sporty"],
    // options: ["Classic & Timeless", "Trendy & Bold", "Casual & Laid-back", "Minimalist & Clean", "Bohemian", "Chic", "Edgy & Modern", "Streetwear", "Sporty", "Festive Diwali Styles"],
  },
  {
    question: "How do you like your clothes to fit? The right fit makes all the difference",
    type: "dropdown",
    // options: ["Relaxed Fit", "Tailored Fit", "Oversized Fit", "Snug Fit", "Regular Fit", "Flared & Flowing"],
    options: [
      { label: "Relaxed Fit", icon: "😌", value: "Relaxed Fit" },
      { label: "Tailored Fit", icon: "✂️", value: "Tailored Fit" },
      { label: "Oversized Fit", icon: "👕", value: "Oversized Fit" },
      { label: "Snug Fit", icon: "🤏", value: "Snug Fit" },
      { label: "Regular Fit", icon: "👌", value: "Regular Fit" },
      { label: "Flared & Flowing", icon: "💃", value: "Flared & Flowing" },
    ],    
  },
  {
    question: "Where's your next style destination? Tell us where you'll be rocking this look",
    type: "dropdown",
    options: [
      { label: "United States", icon: "🇺🇸", value: "United States" },
      { label: "Canada", icon: "🇨🇦", value: "Canada" },
      { label: "United Kingdom", icon: "🇬🇧", value: "United Kingdom" },
      { label: "Germany", icon: "🇩🇪", value: "Germany" },
      { label: "France", icon: "🇫🇷", value: "France" },
      { label: "Italy", icon: "🇮🇹", value: "Italy" },
      { label: "Spain", icon: "🇪🇸", value: "Spain" },
      { label: "India", icon: "🇮🇳", value: "India" },
      { label: "China", icon: "🇨🇳", value: "China" },
      { label: "Japan", icon: "🇯🇵", value: "Japan" },
      { label: "South Korea", icon: "🇰🇷", value: "South Korea" },
      { label: "Singapore", icon: "🇸🇬", value: "Singapore" },
      { label: "Australia", icon: "🇦🇺", value: "Australia" },
      { label: "New Zealand", icon: "🇳🇿", value: "New Zealand" },
      { label: "Brazil", icon: "🇧🇷", value: "Brazil" },
      { label: "Argentina", icon: "🇦🇷", value: "Argentina" },
      { label: "South Africa", icon: "🇿🇦", value: "South Africa" },
      { label: "Nigeria", icon: "🇳🇬", value: "Nigeria" },
      { label: "Egypt", icon: "🇪🇬", value: "Egypt" },
      { label: "Saudi Arabia", icon: "🇸🇦", value: "Saudi Arabia" },
      { label: "United Arab Emirates", icon: "🇦🇪", value: "United Arab Emirates" },
      { label: "Mexico", icon: "🇲🇽", value: "Mexico" },
      { label: "Russia", icon: "🇷🇺", value: "Russia" },
      { label: "Turkey", icon: "🇹🇷", value: "Turkey" },
      { label: "Indonesia", icon: "🇮🇩", value: "Indonesia" },
      { label: "Thailand", icon: "🇹🇭", value: "Thailand" },
      { label: "Vietnam", icon: "🇻🇳", value: "Vietnam" },
      { label: "Malaysia", icon: "🇲🇾", value: "Malaysia" },
      { label: "Philippines", icon: "🇵🇭", value: "Philippines" },
      { label: "Kenya", icon: "🇰🇪", value: "Kenya" },
      { label: "Ghana", icon: "🇬🇭", value: "Ghana" },
    ],
    // options: ["Popular Cities - New York, Paris, Tokyo, Los Angeles, Sydney, Dubai, Cape Town", "Tropical Destination - Maldives, Bali, Hawaii, Fiji", "Mountain Destination - Swiss Alps, Rockies, Himalayas", "Coastal Destination - Amalfi Coast, Greek Islands, Caribbean", "Adventure Destinations - Iceland, Patagonia, Machu Picchu", "Other - Specify your destination"],
  },
  {
    question: "Any favorite colors or palettes you'd like to stick to?",
    type: "dropdown",
    // options: ["Classic Monochrome - Black, White, Gray", "Neutrals - Beige, Brown, Cream", "Earth Tones - Olive, Rust, Sand, Terracotta", "Bold Colors - Red, Blue, Green, Yellow", "Pastels - Soft Pink, Baby Blue, Mint, Lavender", "Metallics - Gold, Silver, Bronze", "Bright & Vibrant - Hot Pink, Neon Green, Electric Blue", "Festive Colors", "Custom Colors - Specify your own colors"],
    options: [
      { label: "Classic Monochrome - Black, White, Gray", icon: "⚫⚪", value: "Classic Monochrome" },
      { label: "Neutrals - Beige, Brown, Cream", icon: "🤎", value: "Neutrals" },
      { label: "Earth Tones - Olive, Rust, Sand, Terracotta", icon: "🌿", value: "Earth Tones" },
      { label: "Bold Colors - Red, Blue, Green, Yellow", icon: "🌈", value: "Bold Colors" },
      { label: "Pastels - Soft Pink, Baby Blue, Mint, Lavender", icon: "🎨", value: "Pastels" },
      { label: "Metallics - Gold, Silver, Bronze", icon: "✨", value: "Metallics" },
      { label: "Bright & Vibrant - Hot Pink, Neon Green, Electric Blue", icon: "💡", value: "Bright & Vibrant" },
      { label: "Festive Colors", icon: "🎉", value: "Festive Colors" },
    ],    
  },
  {
    question: "Choose an image that represents your best self. Don't worry; it's just between us",
    type: "file",
  },
  {
    question: "Please enter your name.",
    type: "text",
  },
  {
    question: "Enter your Email Address",
    type: "text",
  },
];

const DynamicForm = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);  
  const [answers, setAnswers] = useState({});
  const [direction, setDirection] = useState(1);
  const [submitted, setSubmitted] = useState(false); // State to track submission status

  const handleAnswerChange = (e) => {
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    setAnswers({
      ...answers,
      [currentQuestion]: value,
    });
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setDirection(1);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit(); // Call the submit function on the last question
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setDirection(-1);
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleRemoveImage = () => {
    setAnswers({
      ...answers,
      [currentQuestion]: null,
    });
  };

  const formDataQuestions = ["country", "bodyType", "season", "eventType", "preferredStyle", "fitPreference", "destinationPlace", "colorPreference", "file", "name", "email"]

  const handleSubmit = async () => {
    const formData = new FormData();
    Object.keys(answers).forEach((key) => {
      const answer = answers[key];
      formData.append(formDataQuestions[key], answer);
      // formData.append(`question_${key}`, answer);
      // console.log(answer);
    });

    try {
      const response = await axios.post('https://www.apis.zukino.site/process_images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        mode: 'no-cors'
      });
      if (response.status === 204) {
        // setSubmitted(true);
        alert("Your outfit search limit is over. Please contact us at founders@stylemind.zukino.co.in for more searches.");
        return;
      }
      if (response.status >= 200 && response.status < 300) {
        // Success
        // const { request_id } = response.data;
        setSubmitted(true);
        // console.log("Request ID:", request_id);
      } else if (response.status >= 400 && response.status < 500) {
        // Client error
        console.error("Client error:", response.status, response.statusText);
      } else if (response.status >= 500) {
        // Server error
        console.error("Server error:", response.status, response.statusText);
      } else {
        // Other responses
        console.warn("Unexpected response:", response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const renderQuestionField = () => {
    const current = questions[currentQuestion];

    if (current.type === "dropdown") {
      return (
        <div className="relative w-full max-w-md mx-auto">
          <select
            value={answers[currentQuestion] || ""}
            onChange={handleAnswerChange}
            className="appearance-none px-4 py-3 pr-10 rounded-md bg-gray-700 text-white border border-purple-500 focus:border-purple-300 shadow-md hover:shadow-lg transition-all duration-300 focus:outline-none w-full text-sm sm:text-base md:text-lg"
          >
            <option value="" disabled>Select an option</option>
            {current.options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label} - {option.icon}
              </option>
            ))}
          </select>
          <FaChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none" />
        </div>
      );
    } else if (current.type === "text") {
      return (
        <input
          type="text"
          value={answers[currentQuestion] || ""}
          onChange={handleAnswerChange}
          className="px-4 py-3 rounded-lg bg-gray-800 text-white border border-purple-500 focus:border-purple-300 transition-all duration-300 focus:outline-none shadow-md hover:shadow-lg w-full"
          placeholder="Type your answer"
        />
      );
    } else if (current.type === "file") {
      return (
        <div className="flex flex-col items-center space-y-4">
          <h2 className="text-lg text-center text-white-100 mb-4">
            *Kindly upload an image that contains only yourself, in either JPG/JPEG or PNG format.
          </h2>
          <label className="cursor-pointer flex items-center space-x-2 px-4 py-3 rounded-lg bg-gray-800 text-white border border-purple-500 shadow-md hover:shadow-lg transition-all duration-300">
            <FaUpload className="text-xl" />
            <span>Upload Image</span>
            <input
              type="file"
              onChange={handleAnswerChange}
              className="hidden"
              accept="image/*"
            />
          </label>
          {answers[currentQuestion] && (
            <div className="relative">
              <img
                src={URL.createObjectURL(answers[currentQuestion])}
                alt="Preview"
                className="w-40 h-40 object-cover rounded-lg shadow-md"
              />
              <button
                onClick={handleRemoveImage}
                className="absolute top-1 right-1 text-red-500 text-lg hover:text-red-700 focus:outline-none"
              >
                <FaTimesCircle />
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  const isAnswered = answers[currentQuestion];

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex items-center justify-center bg-gradient-to-br via-purple-800 to-purple-700 text-white"
    >
      <div className="bg-gray-900 bg-opacity-80 p-12 rounded-lg shadow-lg w-full max-w-md">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentQuestion}
            initial={{ opacity: 0, x: direction === 1 ? 100 : -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction === 1 ? -100 : 100 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col space-y-6"
          >
            {!submitted ? (
              <>
              <h2 className="text-2xl font-semibold text-center text-white-200 mb-4">
                {questions[currentQuestion].question}
              </h2>
              
              {renderQuestionField()}

              <div className="flex justify-between items-center mt-6">
                {currentQuestion > 0 && (
                  <button
                    onClick={handleBack}
                    className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50"
                  >
                    Back
                  </button>
                )}
                <button
                  onClick={handleNext}
                  disabled={!isAnswered}
                  className={`px-4 py-2 rounded shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50 ml-auto ${
                    isAnswered
                      ? "bg-purple-500 hover:bg-purple-600 text-white"
                      : "bg-gray-500 text-gray-300 cursor-not-allowed"
                  }`}
                >
                  {currentQuestion < questions.length - 1 ? "Next" : "Submit"}
                </button>
              </div>
              </>
            ) : (
              <div className="text-center">
                <h2 className="text-3xl font-semibold text-purple-200">
                  Thank you for your submission!
                </h2>
                <p className="text-lg text-white-300 mt-4">
                  We'll get back to you soon with personalized recommendations.
                </p>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default DynamicForm;
