import React, { useState } from 'react';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import logo_white from '../assets/img/logo_white.ico'
// import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <footer className="bg-gray-800 text-gray-300 py-12">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {/* Company Info */}
            <div>
              <div className="flex items-center">
                <img 
                  src={logo_white} height={70} width={70}
                  alt="Fashion Design"
                  className="rounded-lg shadow-lg mx-auto mb-2"
                />
                {/* <h3 className="px-2">Zukino StyleMind</h3> */}
              </div>
              <h2 className="text-2xl font-bold text-white mb-2">Zukino StyleMind</h2>
              <p className="text-gray-400 mb-4">
                Where AI Meets Your Style. Personalized fashion, designed by AI to make your wardrobe a reflection of you.
              </p>
              <a
                href="#about"
                className="text-purple-500 hover:text-white transition duration-300"
              >
                Learn More About Us &rarr;
              </a>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-xl font-semibold text-white mb-4">Quick Links</h3>
              <ul className="space-y-3">
                <li><a href="#steps" className="hover:text-purple-500 transition duration-300">How It Works</a></li>
                <li><a href="#features" className="hover:text-purple-500 transition duration-300">Features</a></li>
                <li><a href="https://calendar.app.google/R1ZrcwuLZcVBR3yTA" className="hover:text-purple-500 transition duration-300">B2B Solutions</a></li>
                <li><a href="#launch-app" className="hover:text-purple-500 transition duration-300">B2C Solutions</a></li>
                <li><a href="#about" className="hover:text-purple-500 transition duration-300">About Us</a></li>
                {/* <li><a href="#get-outfit" className="hover:text-purple-500 transition duration-300">Get Outfit</a></li> */}
              </ul>
            </div>

            {/* Contact Information */}
            <div>
              <h3 className="text-xl font-semibold text-white mb-4">Contact Us</h3>
              <p className="text-gray-400 mb-2">5900 BALCONES DR 15666, AUS TX, USA</p>
              {/* <p className="text-gray-400 mb-2">123 Fashion Ave, Style City, USA</p> */}
              <p className="text-gray-400 mb-2">Email: Founders@stylemind.zukino.co.in</p>
              <p className="text-gray-400">Phone: +91-9091203984</p>
            </div>

            {/* Social Media */}
            <div className="text-center">
              <h3 className="text-xl font-semibold text-white mb-4">Follow Us</h3>
              <div className="flex justify-center space-x-4">
                {/* <a href="https://facebook.com" className="text-gray-400 hover:text-purple-500 transition duration-300">
                  <FaFacebookF size={20} />
                </a>
                <a href="https://twitter.com" className="text-gray-400 hover:text-purple-500 transition duration-300">
                  <FaTwitter size={20} />
                </a> */}
                <a href="https://www.instagram.com/zukinollc/" className="text-gray-400 hover:text-purple-500 transition duration-300">
                  <FaInstagram size={20} />
                </a>
                <a href="https://www.linkedin.com/company/zukino" className="text-gray-400 hover:text-purple-500 transition duration-300">
                  <FaLinkedinIn size={20} />
                </a>
              </div>
            </div>
          </div>

          {/* Footer Bottom */}
          <div className="mt-12 border-t border-gray-700 pt-6 flex flex-col md:flex-row justify-between items-center text-sm text-gray-500">
            <p>&copy; {new Date().getFullYear()} Zukino StyleMind. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <button
                onClick={toggleModal}
                className="hover:text-purple-500 transition duration-300 focus:outline-none"
              >
                Privacy Policy
              </button>
              <a href="#terms" className="hover:text-purple-500 transition duration-300">Terms of Service</a>
              <a href="#sitemap" className="hover:text-purple-500 transition duration-300">Sitemap</a>
            </div>
          </div>
        </div>
      </footer>
      {/* Modal Section */}
      {showModal && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center px-4 sm:px-6">
          <div className="bg-white p-6 md:p-8 rounded-lg shadow-lg max-w-4xl w-full mx-auto max-h-[90vh] overflow-y-auto">
            {/* Modal Header */}
            <div className="relative">
              <button
                onClick={toggleModal}
                className="absolute top-1 right-1 text-red-500 text-lg hover:text-red-700 focus:outline-none"
              >
                &times;
              </button>
            </div>
            <h1 className="text-2xl text-center sm:text-3xl font-bold text-gray-800">Privacy Policy</h1>

            {/* Effective Date */}
            <p className="text-sm text-gray-500 text-center mb-6">
              Effective Date: <span className="font-semibold">Oct 2024</span>
            </p>

            {/* Modal Content */}
            <div className="space-y-6 text-gray-700 text-left">
              {/* Section 1 */}
              <section>
                <h2 className="text-lg sm:text-xl font-semibold mb-2">1. Information We Collect</h2>
                <p>We may collect the following types of information:</p>
                <ul className="list-disc ml-6 text-left">
                  <li>
                    <span className="font-semibold ">Personal Information:</span> Includes your name, email address, phone number, and other details you provide.
                  </li>
                  <li>
                    <span className="font-semibold">Usage Data:</span> Includes your IP address, browser type, and interactions with our website.
                  </li>
                  <li>
                    <span className="font-semibold">Cookies and Tracking:</span> Used to enhance user experience.
                  </li>
                </ul>
              </section>

              {/* Section 2 */}
              <section>
                <h2 className="text-lg sm:text-xl font-semibold mb-2">2. How We Use Your Information</h2>
                <p>We use the information to operate and improve our services, personalize user experiences, and comply with legal obligations.</p>
              </section>

              {/* Additional Sections */}
              {[
                { title: '3. How We Share Your Information', content: 'We do not sell your information. However, we may share it with trusted service providers, during business transfers, or to comply with legal requirements.' },
                { title: '4. Cookies and Tracking Technologies', content: 'We use cookies to improve your experience. You can manage cookies through your browser settings.' },
                { title: '5. Data Security', content: 'We employ technical measures to safeguard your information, but no online platform is entirely secure.' },
                { title: '6. Your Rights', content: 'You may have rights under data protection laws to access, correct, or delete your data. Contact us at Founders@stylemind.zukino.co.in.' },
                { title: '7. Contact Us', content: 'If you have any questions about this policy, reach out to us at Founders@stylemind.zukino.co.in.' }
              ].map((section, index) => (
                <section key={index}>
                  <h2 className="text-lg sm:text-xl font-semibold mb-2">{section.title}</h2>
                  <p>{section.content}</p>
                </section>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default Footer;