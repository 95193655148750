import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaUpload, FaTimesCircle, FaChevronDown } from 'react-icons/fa';
import axios from 'axios';

const questions = [
  {
    question: "Get Inspired! Upload Your fav Outfit \n Explore Similar Styles Curated Just for You!",
    type: "file",
  },
  {
    question: "Please enter your name.",
    type: "text",
  },
  {
    question: "Enter your Email Address",
    type: "text",
  },
  {
    question: "Did you like our service?",
    type: "dropdown",
    // options: ["👍", "👎"],
    options : [
      { label: "Liked It", icon: "👍", value: "like" },
      // { label: "Need Improvement", icon: <FaRegEdit className="text-yellow-500" />, value: "improvement" },
      { label: "Dislike", icon: "👎", value: "dislike" },
    ],
  },
];

const GetOutfitForm = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);  
  const [answers, setAnswers] = useState({});
  const [direction, setDirection] = useState(1);
  const [submitted, setSubmitted] = useState(false); // State to track submission status
  const [Loading, setLoading] = useState(false); // State to track submission status
  const [imageGallery, setImageGallery] = useState([]);

  const handleAnswerChange = (e) => {
    const value = e.target.type === "file" ? e.target.files[0] : e.target.value;
    setAnswers({
      ...answers,
      [currentQuestion]: value,
    });
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setDirection(1);
      setCurrentQuestion(currentQuestion + 1);
    } else {
      handleSubmit(); // Call the submit function on the last question
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setDirection(-1);
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const handleRemoveImage = () => {
    setAnswers({
      ...answers,
      [currentQuestion]: null,
    });
  };

  const formDataQuestions = ["file", "email", "feedback"]

  const handleSubmit = async () => {
    const formData = new FormData();
    Object.keys(answers).forEach((key) => {
      const answer = answers[key];
      formData.append(formDataQuestions[key], answer);
      // console.log(answer);
    });

    const MIN_LOADING_TIME = 20000; // Minimum time to show the spinner (in milliseconds)
    const startTime = Date.now();

    try {
      
      // Submit form data to the backend
        const response = await axios.post('https://www.apis.zukino.site/get_img_links', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setLoading(true);
    
        // Check the status code of the response
        if (response.status === 204) {
          // setLoading(false);
          alert("Your outfit search limit is over. Please contact us at founders@stylemind.zukino.co.in for more searches.");
          return;
        }
        if (response.status >= 200 && response.status < 300) {
            // Success
            console.log("Submitted");
            // const image_data = response.data;
            setImageGallery(response.data || []); // Assuming the response contains an "images" array
            // console.log("Request ID:", image_data);
            setSubmitted(true);

        } else if (response.status >= 400 && response.status < 500) {
            // Client error
            console.error("Client error:", response.status, response.statusText);
        } else if (response.status >= 500) {
            // Server error
            console.error("Server error:", response.status, response.statusText);
        } else {
            // Other responses
            console.warn("Unexpected response:", response.status, response.statusText);
        }

    } catch (error) {
      // Handle network errors or other unexpected issues
      if (error.response) {
          // Server responded with a status code out of the 2xx range
          console.error("Error response:", error.response.status, error.response.data);
      } else if (error.request) {
          // No response received from server
          console.error("No response received:", error.request);
      } else {
          // Error setting up the request
          console.error("Error setting up request:", error.message);
      }
    } finally {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = MIN_LOADING_TIME - elapsedTime;

        if (remainingTime > 0) {
            setTimeout(() => {
                setLoading(false); // End the loading state after minimum loading time
            }, remainingTime);
        } else {
            setLoading(false); // End loading immediately if elapsed time is greater than the minimum
        }
    }
  };

  const renderQuestionField = () => {
    const current = questions[currentQuestion];

    if (current.type === "dropdown") {
      return (
        <div className="relative w-full max-w-md mx-auto">
          <select
            value={answers[currentQuestion] || ""}
            onChange={handleAnswerChange}
            className="appearance-none px-4 py-3 pr-12 rounded-md bg-gray-700 text-white border border-purple-500 focus:border-purple-300 shadow-md hover:shadow-lg transition-all duration-300 focus:outline-none w-full sm:py-4 sm:pr-16 text-sm sm:text-base"
          >
            <option value="" disabled>Select an option</option>
            {current.options.map((option, index) => (
              <option key={index} value={option.value}>
              {option.label} - {option.icon}
              </option>
            ))}
          </select>
          <FaChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none sm:right-6" />
        </div>
      );
    } else if (current.type === "text") {
      return (
        <input
          type="text"
          value={answers[currentQuestion] || ""}
          onChange={handleAnswerChange}
          className="px-4 py-3 rounded-lg bg-gray-800 text-white border border-purple-500 focus:border-purple-300 transition-all duration-300 focus:outline-none shadow-md hover:shadow-lg w-full"
          placeholder="Type your answer"
        />
      );
    } else if (current.type === "file") {
      return (
        <div className="flex flex-col items-center space-y-4">
          <label className="cursor-pointer flex items-center space-x-2 px-4 py-3 rounded-lg bg-gray-800 text-white border border-purple-500 shadow-md hover:shadow-lg transition-all duration-300">
            <FaUpload className="text-xl" />
            <span>Upload Image</span>
            <input
              type="file"
              onChange={handleAnswerChange}
              className="hidden"
              accept="image/*"
            />
          </label>
          {answers[currentQuestion] && (
            <div className="relative">
              <img
                src={URL.createObjectURL(answers[currentQuestion])}
                alt="Preview"
                className="w-40 h-40 object-cover rounded-lg shadow-md"
              />
              <button
                onClick={handleRemoveImage}
                className="absolute top-1 right-1 text-red-500 text-lg hover:text-red-700 focus:outline-none"
              >
                <FaTimesCircle />
              </button>
            </div>
          )}
        </div>
      );
    }
  };

  const isAnswered = answers[currentQuestion];

  const renderImageGallery = () => {

    // Check if imageGallery is valid and has content
    if (!imageGallery || imageGallery.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center p-6">
          <h2 className="text-3xl font-bold mb-10 text-white-500">Oops! No images found</h2>
          {/* <h2 className="text-lg font-semibold text-white-600">No images available</h2> */}
          <p className="text-white-500">It looks like we don't have any images to show right now. Try refreshing the page or check back later!</p>
        </div>
      );
    }

    return (
      // className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 p-4">
        {imageGallery
          .filter(
            (item) =>
              item.title &&
              item.link &&
              // item.price &&
              !["youtube.com", "instagram.com", "in.pinterest.com", "stablediffusionweb.com", "facebook.com"].some((src) =>
                new URL(item.link).hostname.includes(src)
              )
          )
          .map((image, index) => (
            <div
              className="relative group bg-gray-900 rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105"
              key={index}
            >
              <a href={image.link || "#"} target="_blank" rel="noopener noreferrer">
                {/* Image */}
                <div className="flex items-center justify-center bg-gray-900">
                  <img
                    className="object-cover object-center"
                    src={image.thumbnail || "default-thumbnail.jpg"} // Provide a default image
                    alt={image.title || "Untitled Image"} // Provide an alt text
                  />
                </div>

                {/* Metadata */}
                <div className="p-4 bg-gray-900 text-white">
                  {/* Title */}
                  <h3 className="text-lg font-semibold text-purple-300 group-hover:text-purple-500 transition-colors duration-300">
                    {image.title || "No Title Available"}
                  </h3>

                  {/* Info Row */}
                  <div className="flex items-center justify-between mt-2 text-sm">
                    {/* Price */}
                    <span className="font-medium text-green-400">
                      {image.price && image.price.value ? image.price.value : "Price Unavailable"}
                    </span>

                    {/* Source Info */}
                    <div className="flex items-center space-x-2">
                      <img
                        className="w-6 h-6 object-cover rounded-full"
                        src={image.source_icon || "default-icon.png"} // Provide a default icon
                        alt={image.source || "Source Icon"}
                      />
                      <span className="text-gray-400">{image.source || "Source Unavailable"}</span>
                    </div>
                  </div>

                  {/* Stock Status */}
                  <div
                    className={`mt-2 text-sm font-medium ${
                      image.in_stock
                        ? "text-green-400"
                        : image.in_stock === false
                        ? "text-red-400"
                        : "text-gray-400"
                    }`}
                  >
                    {image.in_stock === undefined
                      ? "Stock Status Unknown"
                      : image.in_stock
                      ? "In Stock"
                      : "Out of Stock"}
                  </div>
                </div>

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <span className="text-white font-bold text-lg">View Details</span>
                </div>
              </a>
            </div>
          ))}
      </div>
    );
  };

  return (
    <section id="get-outfit">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="min-h-screen flex items-center justify-center bg-gradient-to-br via-purple-800 to-purple-700 text-white"
      >
        <div id="get-outfit" className="bg-gray-900 bg-opacity-80 p-12 rounded-lg shadow-lg">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentQuestion}
              initial={{ opacity: 0, x: direction === 1 ? 100 : -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: direction === 1 ? -100 : 100 }}
              transition={{ duration: 0.5 }}
              className="flex flex-col space-y-6"
            >
              {!submitted ? (
                <>
                <h2 className="text-2xl font-semibold text-center text-white-200 mb-4">
                  {questions[currentQuestion].question}
                </h2>
                
                {renderQuestionField()}

                <div className="flex justify-between items-center mt-6">
                  {currentQuestion > 0 && (
                    <button
                      onClick={handleBack}
                      className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50"
                    >
                      Back
                    </button>
                  )}
                  <button
                    onClick={handleNext}
                    disabled={!isAnswered}
                    className={`px-4 py-2 rounded shadow-md transition duration-300 ease-in-out transform hover:-translate-y-1 focus:outline-none focus:ring-2 focus:ring-purple-400 focus:ring-opacity-50 ml-auto ${
                      isAnswered
                        ? "bg-purple-500 hover:bg-purple-600 text-white"
                        : "bg-gray-500 text-gray-300 cursor-not-allowed"
                    }`}
                  >
                    {currentQuestion < questions.length - 1 ? "Next" : "Submit"}
                  </button>
                </div>
                </>
              ) : (
                <div>
                  <h2 className="text-3xl font-bold mb-10 text-white-500">
                    {/* Discover Your Personalized Collection! */}
                    Your Exclusive Collection Starts Here!
                  </h2>
                  {Loading ? (
                    <p>Loading...</p>
                  ) : (
                    renderImageGallery()
                  )}
                </div>
              )}  
            </motion.div>
          </AnimatePresence>
        </div>
      </motion.div>
    </section>
  );
};

export default GetOutfitForm;
