import React from 'react';
import ranjan from '../assets/img/ranjan.png'
import ankush from '../assets/img/ankush.png'

const AboutUs = () => {
  return (
    <section id="about" className="bg-gray-900 text-gray-300 py-20">
      <div className="container mx-auto px-6 space-y-20">

      <div className="container mx-auto px-6 flex flex-col items-center space-y-12">
        <h2 className="text-3xl font-bold text-white-500 text-center">About Us</h2>
        {/* Welcome Section */}
        <div className="bg-gray-800 text-center rounded-lg p-6 shadow-md">
          <h2 className="text-2xl font-bold text-white-300">Welcome to Zukino StyleMind – Where AI Meets Your Style</h2>
          <p className="text-white-700 mt-4">At <strong>Zukino StyleMind</strong>, we believe that fashion is a personal journey, one that reflects who you are and how you present yourself to the world. We merge the timeless appeal of fashion with cutting-edge artificial intelligence to create a truly personalized experience. Whether you're looking to refresh your wardrobe or explore the latest trends, our AI-driven platform is here to help you craft a style that’s truly yours.</p>
        </div>

        {/* Row 1 */}
        <div className="flex flex-wrap justify-center gap-8">
          {/* Who We Are Section */}
          <div className="bg-gray-800 text-center rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-bold text-white-300">Who We Are</h2>
            <p className="text-white-700 mt-4">Zukino StyleMind is an innovative fashion technology platform designed to provide <strong>AI-powered fashion design solutions</strong>. Our platform empowers both <strong>individuals and fashion brands</strong> with a personalized, trend-driven approach to fashion.</p>
            <h2 className="text-2xl font-bold mt-6 text-white-300">What We Do</h2>
            <p className="text-white-700 mt-4">Our <strong>AI-driven fashion design system</strong> analyzes your unique preferences and generates designs that suit your personality and fit perfectly. Experience virtual try-ons and immerse yourself in fashion like never before.</p>
          </div>
        </div>

        {/* Row 2 */}
        <div className="flex flex-wrap justify-center gap-8">
          {/* Our Vision Section */}
          <div className="bg-gray-800 text-center rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-bold text-white-300">Our Vision</h2>
            <p className="text-white-700 mt-4">We envision a world where fashion is <strong>inclusive, personalized, and sustainable</strong>, helping individuals and brands make smarter fashion decisions.</p>
          </div>

          {/* Why Zukino Section */}
          <div className="bg-gray-800 text-center rounded-lg p-6 shadow-md">
            <h2 className="text-2xl font-bold text-white-300">Why Zukino StyleMind?</h2>
            <ul className="text-white-700 mt-4 list-disc list-inside text-left">
              <li><strong>AI-Powered Personalization:</strong> Every design is personalized to you.</li>
              <li><strong>Seamless Experience:</strong> A smooth, effortless experience from start to finish.</li>
              <li><strong>Virtual Try-Ons:</strong> Try on styles virtually before committing.</li>
              <li><strong>Trend-Driven Designs:</strong> Stay stylish and current with the latest trends.</li>
            </ul>
            <h2 className="text-2xl font-bold mt-6 text-white-300">Our Core Values</h2>
            <ul className="text-white-700 mt-4 list-disc list-inside text-left">
              <li><strong>Innovation:</strong> Pushing the boundaries of fashion tech.</li>
              <li><strong>Personalization:</strong> Tailored styles for every unique individual.</li>
              <li><strong>Sustainability:</strong> Promoting smarter, more sustainable choices.</li>
              <li><strong>Inclusivity:</strong> Fashion for everyone, accessible and inclusive.</li>
            </ul>
          </div>
        </div>

      </div>

        {/* Meet the Team Section with Images */}
        <div className="bg-gray-800 rounded-lg p-6 space-y-10">
          <h2 className="text-3xl font-bold text-white-500 text-center">Meet Our Team</h2>
          <div className="flex flex-wrap justify-center gap-10">

            {/* Team Member 1 */}
            <div className="text-center space-y-4 max-w-xs">
              <img src={ankush} alt="Ankush Banik" className="rounded-full w-40 h-40 mx-auto border-4 border-white-500" />
              <h3 className="text-xl font-bold text-white">Ankush Banik</h3>
              <p className="text-gray-400">Founder & CEO</p>
              <p className="text-sm">A visionary leader driving Zukino StyleMind's transformation in fashion-tech.</p>
            </div>

            {/* Team Member 2 */}
            <div className="text-center space-y-4 max-w-xs">
              <img src={ranjan} alt="Ranjan Pandit" className="rounded-full w-40 h-40 mx-auto border-4 border-white-500" />
              <h3 className="text-xl font-bold text-white">Ranjan Pandit</h3>
              <p className="text-gray-400">CTO</p>
              <p className="text-sm">Architect of our AI, combining technology and creativity for a seamless experience.</p>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
