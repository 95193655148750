import React from 'react';
import classic_outfit from '../assets/img/classic_outfit.jpeg'
import trend_bold from '../assets/img/trends_bold.jpeg'
import casual_outfit from '../assets/img/casual_outfit.jpg'
import Minimalist_outfit from '../assets/img/Minimalist_outfit.jpg'
import boho_outfit from '../assets/img/boho_outfit.jpg'
import Chic_outfit from '../assets/img/Chic_outfit.jpg'
import edgy_outfit from '../assets/img/edgy-outfit.jpg'
import Streetwear_outfit from '../assets/img/Streetwear_outfit.jpg'
import sporty_outfit from '../assets/img/sporty_outfit.jpg'
import traditional_outfit from '../assets/img/traditional_outfit.jpg'
import Romantic_outfit from '../assets/img/Romantic_outfit.jpg'
import Preppy_outfit from '../assets/img/Preppy_outfit.jpg'

const StyleVibesSection = () => {
  const styles = [
    {
      name: 'Classic & Timeless',
      description: 'A refined and elegant style that transcends trends, focusing on wardrobe staples that never go out of fashion. Classic style emphasizes quality, tailored fits, and simplicity, exuding an air of sophistication.',
      key_features: 'Crisp white shirts, tailored blazers, little black dresses, pearls, and leather accessories.',
      image: classic_outfit, // Replace with your image path
    },
    {
      name: 'Trendy & Bold',
      description: 'A cutting-edge, fashion-forward style that embraces the latest trends and makes a statement. Trendy & Bold fashion is about taking risks, mixing patterns, and experimenting with eye-catching designs and vibrant colors.',
      key_features: 'Statement prints, oversized silhouettes, chunky jewelry, and vibrant, bold palettes.',
      image: trend_bold, // Replace with your image path
    },
    {
      name: 'Casual & Laid-back',
      description: 'A relaxed and comfortable style ideal for everyday wear. Casual & Laid-back focuses on effortless pieces that prioritize comfort while maintaining a sense of style, perfect for leisurely outings or at-home lounging.',
      key_features: 'T-shirts, relaxed-fit jeans and cozy knits.',
      image: casual_outfit, // Replace with your image path
    },
    {
      name: 'Minimalist & Clean',
      description: 'A sleek and understated style that focuses on simplicity and functionality. Minimalist fashion highlights neutral tones, clean lines, and versatile pieces that blend seamlessly for a modern, elegant look.',
      key_features: 'Monochrome outfits, structured coats, straight-leg trousers, and simple leather bags.',
      image: Minimalist_outfit, // Replace with your image path
    },
    {
      name: 'Bohemian',
      description: 'A free-spirited, artistic style characterized by flowing fabrics, earthy tones, and eclectic patterns. Bohemian fashion often includes elements like fringe, embroidery, and natural materials, evoking a relaxed, wanderlust-inspired look.',
      key_features: 'Flowy maxi dresses, wide-brim hats, layered jewelry, and ethnic prints.',
      image: boho_outfit, // Replace with your image path
    },
    {
      name: 'Chic',
      description: 'An effortlessly polished and sophisticated look that embodies elegance and simplicity. Chic style often combines timeless staples with modern trends, creating a refined yet approachable appearance.',
      key_features: 'Tailored blazers, neutral color palettes, sleek accessories, and minimalist heels.',
      image: Chic_outfit, // Replace with your image path
    },
    {
      name: 'Edgy & Modern',
      description: 'A bold, contemporary aesthetic that pushes fashion boundaries with unconventional cuts, dark tones, and statement pieces. This style exudes confidence and a rebellious attitude, blending sharp lines with creative textures.',
      key_features: 'Leather jackets, distressed jeans, metallic accents, and asymmetrical designs.',
      image: edgy_outfit, // Replace with your image path
    },
    {
      name: 'Streetwear',
      description: 'A casual yet trendy style rooted in urban culture and inspired by music, sports, and art. Streetwear emphasizes oversized fits, bold graphics, and a mix of luxury and everyday wear.',
      key_features: 'Hoodies, sneakers, graphic tees, and cargo pants.',
      image: Streetwear_outfit, // Replace with your image path
    },
    {
      name: 'Sporty',
      description: 'A functional yet stylish look influenced by activewear and athleisure trends. Sporty style combines performance fabrics with contemporary design, offering comfort and versatility for everyday wear.',
      key_features: 'Joggers, zip-up hoodies, running sneakers, and sleek workout tops.',
      image: sporty_outfit, // Replace with your image path
    },   
    {
      name: 'Traditional',
      description: 'A rich and culturally inspired style that celebrates heritage through ornate designs, vibrant patterns, and traditional craftsmanship. Traditional fashion often includes ceremonial wear and timeless ethnic attire.',
      key_features: 'Sarees, lehengas, sherwanis, kimono-inspired designs, and intricate embroidery.',
      image: traditional_outfit, // Replace with your image path
    },
    {
      name: 'Romantic',
      description: 'A soft and feminine style that emphasizes delicate fabrics, pastel colors, and floral patterns. Romantic fashion creates an ethereal and whimsical vibe perfect for special occasions or dreamy looks.',
      key_features: 'Flowy dresses, lace details, ruffles, and soft pastel tones.',
      image: Romantic_outfit, // Replace with your image path
    },
    {
      name: 'Preppy',
      description: 'A polished and youthful style inspired by collegiate and Ivy League fashion. Preppy outfits emphasize classic patterns, neat tailoring, and a cheerful, clean aesthetic.',
      key_features: 'Plaid skirts, button-up shirts, loafers, and sweaters tied around the shoulders.',
      image: Preppy_outfit, // Replace with your image path
    }, 
    // Add more styles here
  ];

  // const styles = [
  //   {
  //     name: 'Classic & Timeless',
  //     description: 'Elegant and everlasting looks that never go out of style.',
  //     key_features: 'Elegant and everlasting looks that never go out of style.',
  //     image: '/images/classic.jpg', // Replace with your image path
  //   },
  //   {
  //     name: 'Trendy & Bold',
  //     description: 'Fashion-forward and statement-making designs.',
  //     image: '/images/trendy.jpg',
  //   },
  //   {
  //     name: 'Minimalist & Clean',
  //     description: 'Simple, sleek, and modern style for any occasion.',
  //     image: '/images/minimalist.jpg',
  //   },
  //   {
  //     name: 'Bohemian',
  //     description: 'A free-spirited, artistic style characterized by flowing fabrics, earthy tones, and eclectic patterns. Bohemian fashion often includes elements like fringe, embroidery, and natural materials, evoking a relaxed, wanderlust-inspired look.',
  //     image: '/images/classic.jpg', // Replace with your image path
  //   },
  //   {
  //     name: 'Chic',
  //     description: 'Elegant, sophisticated, and polished.',
  //     image: '/images/trendy.jpg',
  //   },
  //   {
  //     name: 'Edgy & Modern',
  //     description: 'Bold, unique, and a bit rebellious.',
  //     image: '/images/casual.jpg',
  //   },
  //   {
  //     name: 'Streetwear',
  //     description: 'Urban-inspired, comfortable, and stylish.',
  //     image: '/images/minimalist.jpg',
  //   },
  //   {
  //     name: 'Sporty',
  //     description: 'Functional and active-oriented.',
  //     image: 'https://images.unsplash.com/photo-1515886657613-9f3515b0c78f?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  //   },
  //   // Add more styles here
  // ];

  return (
    <section id="vibe-info" className="bg-gray-900 bg-opacity-80 p-12 rounded-lg shadow-lg">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-white mb-8">
          Discover Your Style Vibe
        </h2>
        <p className="text-center text-white mb-12">
          Explore unique style vibes tailored to your personality. Find the look that speaks to you.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {styles.map((style, index) => (
            <div
              key={index}
              className="relative bg-gray-900 rounded-lg shadow-lg overflow-hidden group hover:shadow-2xl transition duration-300"
            >
              <div className="flex items-center justify-center bg-gray-900 rounded-lg">
                <img
                  src={style.image}
                  alt={style.name}
                  className="rounded-lg h-56 object-cover object-center group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <div className="p-4 bg-gray-900">
                <h3 className="text-lg font-semibold text-purple-400">{style.name}</h3>
                <p className="text-sm text-white-700 mt-2">{style.description}</p>
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <p className="text-white font-semibold text-center text-sm px-4">
                  {style.key_features}.
                </p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="text-center mt-12">
          <button className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition">
            Discover Your Style
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default StyleVibesSection;
