// import './App.css';
// import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import StepsSection from '../components/StepsSection'
import FeaturesSection from '../components/FeaturesSection'
import ExploreOurDesigns from '../components/ExploreOurDesigns';
// import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';
// import DynamicForm from './DynamicForm';
import React, { useState } from 'react';
import LaunchApp from '../Constants/LaunchApp';
import GetOutfitForm from './GetOutfitForm';
import StyleVibesSection from '../components/StyleVibesSection';


function LandingPage() {
  const [isIntroVisible, setIsIntroVisible] = useState(true);

  const handleLaunch = () => {
    setIsIntroVisible(false);
  };
  return (
    <div className="App">
      {/* <Navbar /> */}
      {isIntroVisible ? (
          <HeroSection onLaunch={handleLaunch} key="intro" />
        ) : (
          <LaunchApp key="form" />
        )}
      {/* <HeroSection /> */}
      {/* <DynamicForm /> */}
      <StepsSection />
      <FeaturesSection />
      <ExploreOurDesigns />
      <LaunchApp />
      <StyleVibesSection />
      <GetOutfitForm />
      <AboutUs />
      {/* <Footer /> */}
    </div>
  );
}

export default LandingPage;
