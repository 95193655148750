// src/components/ResponsiveNavbar.js
import React, { useState } from 'react';
// import logo from '../assets/img/logo.png'
import logo_white from '../assets/img/logo_white.ico'

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-900 text-white sticky top-0 z-50 shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo */}
        {/* <div className="text-2xl font-bold text-purple-500">Zukino StyleMind</div> */}
        <div className="flex items-center">
          <img 
            src={logo_white} height={50} width={50}
            alt="Fashion Design"
            className="rounded-lg shadow-lg mx-auto"
          />
          <h3 className="px-2">Zukino StyleMind</h3>
        </div>

        {/* Hamburger Icon */}
        <div className="md:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="focus:outline-none text-white hover:text-purple-500"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>

        {/* Links */}
        <div
          className={`${
            isOpen ? 'block' : 'hidden'
          } w-full md:flex md:items-center md:w-auto md:space-x-6 space-y-4 md:space-y-0 md:bg-transparent bg-gray-900 p-4 md:p-0 absolute top-16 left-0 md:static z-10`}
        >
          <a href="#hero" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>Home</a>
          <a href="#steps" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>How It Works</a>
          <a href="#features" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>Features</a>
          <a href="https://calendar.app.google/R1ZrcwuLZcVBR3yTA" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>B2B Solutions</a>
          <a href="#launch-app" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>B2C Solutions</a>
          <a href="#about" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>About Us</a>
          <a href="#get-outfit" className="block px-2 py-1 hover:text-purple-500" onClick={handleLinkClick}>Get Outfit</a>
          <button
            className="bg-button-gradient text-white px-4 py-2 rounded hover:bg-button-gradient-hv"
            onClick={handleLinkClick}
          >
            <a href="https://calendar.app.google/R1ZrcwuLZcVBR3yTA" className="block py-2 rounded-md text-center hover:bg-button-gradient-hv transition">Request Demo</a>
          </button>
        </div>
        </div>
    </nav>
  );
};


//   return (
//     <nav className="bg-gray-900 text-white sticky top-0 z-50 shadow-md">
//       <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        
//         {/* Logo */}
//         {/* <div className="text-2xl font-bold text-purple-500">Zukino StyleMind</div> */}
//         <div className="flex items-center">
//           <img 
//             src={logo} height={80} width={100}
//             alt="Fashion Design"
//             className="rounded-lg shadow-lg mx-auto"
//           />
//           <h3>Zukino StyleMind</h3>
//         </div>
        
//         {/* Menu button for mobile */}
//         <div className="md:hidden">
//           <button onClick={toggleMenu}>
//             {isOpen ? (
//               <XIcon className="w-6 h-6 text-white" />
//             ) : (
//               <MenuIcon className="w-6 h-6 text-white" />
//             )}
//           </button>
//         </div>
        
//         {/* Nav Links */}
//         <div className={`md:flex items-center space-x-6 ${isOpen ? 'block' : 'hidden'} md:block`}>
//           <a href="#hero" className="hover:text-purple-400">Home</a>
//           <a href="#steps" className="hover:text-purple-400">How It Works</a>
//           <a href="#features" className="hover:text-purple-400">Features</a>
//           <a href="https://calendar.app.google/R1ZrcwuLZcVBR3yTA" className="hover:text-purple-400">B2B Solutions</a>
//           <a href="#hero" className="hover:text-purple-400">B2C Solutions</a>
//           <a href="#about" className="hover:text-purple-400">About Us</a>
//           <a href="#intro" className="hover:text-purple-400">Get Outfit</a>
//           <a href="https://calendar.app.google/R1ZrcwuLZcVBR3yTA" className="bg-purple-500 px-4 py-2 rounded-md hover:bg-purple-600 transition">Request Demo</a>
//         </div>
//       </div>
      
//       {/* Mobile menu */}
//       {isOpen && (
//         <div className="md:hidden bg-gray-800 text-white px-4 py-4">
//           <a href="#home" className="block py-2 hover:text-purple-400">Home</a>
//           <a href="#how-it-works" className="block py-2 hover:text-purple-400">How It Works</a>
//           <a href="#features" className="block py-2 hover:text-purple-400">Features</a>
//           <a href="#b2b-solutions" className="block py-2 hover:text-purple-400">B2B Solutions</a>
//           <a href="#b2c-solutions" className="block py-2 hover:text-purple-400">B2C Solutions</a>
//           <a href="#about-us" className="block py-2 hover:text-purple-400">About Us</a>
//           <a href="#get-outfit" className="block py-2 hover:text-purple-400">Get Outfit</a>
//           <a href="#request-demo" className="block py-2 bg-purple-500 rounded-md text-center hover:bg-purple-600 transition">Request Demo</a>
//         </div>
//       )}
//     </nav>
//   );
// };

export default Navbar;
