// src/components/IntroScreen.js

import React from 'react';
import { motion } from 'framer-motion';

const IntroScreen = ({ onLaunch }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="flex items-center justify-center min-h-screen bg-gradient-to-br via-purple-800 to-purple-700 text-white"
    >
      <div className="text-center space-y-6 p-8 bg-gray-900 bg-opacity-80 rounded-lg shadow-lg">
        <h1 className="text-4xl font-bold">Zukino StyleMind</h1>
        <p className="text-lg text-white-300">
          Your style partner powered by AI. Upload your image and customize your look!
        </p>
        <button
          onClick={onLaunch}
          className="bg-button-gradient hover:bg-button-gradient-hv text-white font-semibold px-6 py-3 rounded shadow-md transition duration-300 transform hover:scale-105 focus:outline-none"
        >
          Launch App
        </button>
      </div>
    </motion.div>
  );
};

export default IntroScreen;
