import React from 'react';
import img_1 from '../assets/img/img1.png'
import img_3 from '../assets/img/img3.png'
import biana2 from '../assets/img/biana2.png'
import biana1 from '../assets/img/biana1.png'

const ExploreOurDesigns = () => {
  const designs = [
    {
      image: `${img_3}`, // Replace with your image URL
      description: 'Stay cozy in style—AI creates the perfect winter outfit with a double-breasted coat and sleek white pants.',
    },
    {
      image: `${biana2}`, // Replace with your image URL
      description: 'Effortless chic—AI designs a relaxed yet stylish off-shoulder jumpsuit for modern fashionistas.',
    },
    {
      // image: 'https://zukino.co.in/img/img1.png', // Replace with your image URL
      image: `${img_1}`, // Replace with your image URL
      description: 'AI merges sophistication and simplicity in this tailored beige blazer and black pants ensemble, ideal for any formal occasion.',
    },
    {
      image: `${biana1}`, // Replace with your image URL
      description: 'Embrace timeless elegance with this AI-crafted floral dress, perfect for any outdoor event.',
    },
  ];

  return (
    <section id="explore" className="bg-gray-900 text-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-center text-3xl font-semibold mb-8 text-white">Explore Our Designs</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {designs.map((design, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg shadow-lg overflow-hidden p-4 border border-purple-700 hover:border-purple-500 transition duration-300"
            >
              <div className="overflow-hidden rounded-lg">
                <img
                  src={design.image}
                  alt={`Design ${index + 1}`}
                  className="w-full h-80 object-cover transform transition-transform duration-300 hover:scale-105"
                />
              </div>
              <p className="text-center text-white mt-4">{design.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExploreOurDesigns;
