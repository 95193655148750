// src/components/HeroSection.js
import React from 'react';
// import hero_1 from '../assets/img/hero_1.jpg'

const HeroSection = () => {
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <section id="hero" className="bg-gray-900 text-white py-20 px-6 text-center md:text-left">
      <div className="container mx-auto flex flex-col md:flex-row items-center">
        
        {/* Text Section */}
        <div className="md:w-1/2 space-y-6">
          <h1 className="text-4xl md:text-5xl font-bold text-white-500">
            Personalized Fashion, Designed by AI.
          </h1>
          <p className="text-lg md:text-xl text-gray-300">
            Your AI-driven fashion designer for custom styles and trend-driven outfits delivered to your wardrobe.
          </p>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 justify-center md:justify-start">
            <button className="bg-button-gradient hover:bg-button-gradient-hv text-white px-6 py-3 rounded shadow-md" onClick={() => scrollToSection('launch-app')}>
              Try NOW
            </button>
            <button className="bg-transparent border-2 border-purple-500 text-purple-500 hover:bg-button-gradient-hv hover:text-white px-6 py-3 rounded shadow-md" onClick={() => scrollToSection('steps')}>
              How it works?
            </button>
          </div>
        </div>
        {/* Professional YouTube Video Section */}
        <div className="md:w-1/2 mt-10 md:mt-0">
          <div className="relative w-full h-0 pb-[56.25%]">
            <iframe
              src="https://www.youtube.com/embed/AFKUR5gaCdI?controls=0&modestbranding=1&rel=0&autoplay=1&mute=0&loop=1&cc_load_policy=0&hl=en&playlist=AFKUR5gaCdI"
              title="YouTube Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
