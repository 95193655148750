// import './App.css';
// import Navbar from '../components/Navbar'
// import Footer from '../components/Footer';
import DynamicForm from './DynamicForm';
import IntroScreen from '../components/IntroScreen'
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';


function LaunchApp() {
  const [isIntroVisible, setIsIntroVisible] = useState(true);

  const handleLaunch = () => {
    setIsIntroVisible(false);
  };

  return (
    <section id="launch-app">
      <AnimatePresence>
        {isIntroVisible ? (
          <IntroScreen onLaunch={handleLaunch} key="intro" />
        ) : (
          <DynamicForm key="form" />
        )}
      </AnimatePresence>
    </section>
  );
};

export default LaunchApp;
