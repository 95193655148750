import './App.css';
// import LaunchApp from './Constants/LaunchApp';
import Navbar from './components/Navbar'
// import HeroSection from './components/HeroSection'
// import StepsSection from './components/StepsSection'
// import FeaturesSection from './components/FeaturesSection'
// import ExploreOurDesigns from './components/ExploreOurDesigns';
import Footer from './components/Footer';
// import AboutUs from './components/AboutUs';
import LandingPage from './Constants/LandingPage';
// import { AnimatePresence } from 'framer-motion';
// import React, { useState } from 'react';


function App() {
  // const [isIntroVisible, setIsIntroVisible] = useState(true);

  // const handleLaunch = () => {
  //   setIsIntroVisible(false);
  // };
  return (
    <div className="App">
      <Navbar />
      {/* <AnimatePresence>
        {isIntroVisible ? (
          <LandingPage onLaunch={handleLaunch} key="intro" />
        ) : (
          <LaunchApp key="form" />
        )}
      </AnimatePresence> */}
      <LandingPage />
      {/* <LaunchApp /> */}
      <Footer />
    </div>
  );
}

export default App;
