// src/components/StepsSection.js
import React from 'react';

const FeaturesSection = () => {
  const steps = [
    {
      id: 1,
      title: "Generative AI Fashion Designer",
      description: "Our AI creates fashion designs that align with the latest trends while incorporating your unique style preferences.",
    },
    {
      id: 2,
      title: "Virtual Try-On",
      description: "Experience the future of shopping by trying on clothes virtually before placing an order.",
    },
    {
      id: 3,
      title: "Customization & Fit",
      description: "Zukino StyleMind ensures that every design fits you perfectly, taking into account your exact measurements and style preferences.",
    },
    {
      id: 4,
      title: "Sustainability and Smart Choices",
      description: "Our AI also recommends sustainable and eco-friendly fashion choices, helping you make conscious decisions.",
    },
  ];

  return (
    <section id="features" className="bg-gray-900 text-white py-20 px-6">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-10 text-white-500">Reimagine Fashion with Cutting-Edge AI</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step) => (
            <div
              key={step.id}
              className="bg-gray-800 p-6 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:bg-gray-700"
            >
              <h3 className="text-xl font-semibold mb-4 text-purple-400">{step.title}</h3>
              <p className="text-gray-300">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};


export default FeaturesSection;
