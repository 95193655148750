// src/components/StepsSection.js
import React from 'react';

const StepsSection = () => {
  const steps = [
    {
      id: 1,
      title: "Step 1",
      description: "Enter Your Preferences: Tell us your style, favorite trends, and body measurements.",
    },
    {
      id: 2,
      title: "Step 2",
      description: "AI-Driven Fashion Creation: Zukino’s AI creates customized, trend-driven designs just for you.",
    },
    {
      id: 3,
      title: "Step 3",
      description: "Virtual Try-On: See how your personalized designs look on you with our virtual try-on feature.",
    },
    {
      id: 4,
      title: "Step 4",
      description: "Receive Your Design: Receive your design virtually or place an order to get it tailored to your measurements.",
    },
  ];

  return (
    <section id="steps" className="bg-gray-900 text-white py-20 px-6">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-10 text-white-500">How Zukino StyleMind Transforms Fashion</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step) => (
            <div
              key={step.id}
              className="bg-gray-800 p-6 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 hover:shadow-xl hover:bg-gray-700"
            >
              <h3 className="text-xl font-semibold mb-4 text-purple-400">{step.title}</h3>
              <p className="text-gray-300">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
